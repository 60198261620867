import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.2.31098" />
      <meta name="build-date" content="2024-10-10T19:04:55Z" />
    </Head>
  );
};

export default Versioning;
